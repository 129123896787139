import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Dorsey's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3">“Prior to the tapes, I used many “self-help”-style books. Some helped, others not so much, but few had a lasting influence. That all changed with Effective Learning.”</h1>
              <div className="div-block"><img width={74} src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Dorsey</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5ddc32c902b5e177334247aa_man-with-eyes-and-ears-tightly-closed-with-hands_t20_pxpjWY.jpg" alt="" sizes="(max-width: 479px) 95vw, (max-width: 767px) 90vw, (max-width: 991px) 612px, 46vw" srcSet="/images/5ddc32c902b5e177334247aa_man-with-eyes-and-ears-tightly-closed-with-hands_t20_pxpjWY-p-500.jpeg 500w, /images/5ddc32c902b5e177334247aa_man-with-eyes-and-ears-tightly-closed-with-hands_t20_pxpjWY-p-800.jpeg 800w, /images/5ddc32c902b5e177334247aa_man-with-eyes-and-ears-tightly-closed-with-hands_t20_pxpjWY.jpg 1045w" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Dorsey</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>Overcoming Fears and Anxiety, Double Your Energy, and Become Super Self-Confident</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>The programs have enabled me to increase my focus on positive outcomes</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>“I wanted to address the negative energies and emotions that seemed to slow my progress to reaching my goals,” begins Dorsey. “Like everyone, I’ve had moments when negative reactions have impaired my ability to move forward towards my goals.” Dorsey’s wife suggested he listen to audio programs from Effective Learning to remove these negative barriers and he has tried several programs from the While-U-Drive series including Overcoming Fears and Anxiety, Double Your Energy, and Become Super Self-Confident.</p>
                  <p>“Prior to the tapes, I used many “self-help”-style books. Some helped, others not so much, but few had a lasting influence. That all changed with Effective Learning.”</p>
                  <p>When<strong> a</strong>sked what’s been most helpful, he responds “One bit of specific advice I’ve found helpful is Mark Twain’s quote: ‘Worry is interest paid in advance for a debt you may never owe.’ This and many other quotes are now part of my everyday coping mechanisms for life. Also, the visualization techniques and examples are very useful for day-to-day situations.”</p>
                  <p>The results for Dorsey have been very encouraging. “The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems. My wife has also benefitted from using the tapes.”</p>
                  <p>As with many users of the programs, love is at its core. “For me, the programs are a positive reminder of God’s love manifesting externally and perhaps more importantly, within me. I simply have to take time to breathe and notice what is happening in my life. Y’all are truly helping.”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Jenny was able to gain more confidence and lose weight using the Wise Guide app</p><Link to="/jenny/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The program explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. </p><Link to="/annie-marie/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49cd315b3039bba6af51_13-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Marlin lost a staggering 70 pounds using The Wise Guide weight loss program</p><Link to="/marlin/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
